/*global gsap*/
import './scss/App.scss';

document.addEventListener('DOMContentLoaded', (event) => {
    console.log('start');

    const quotes = document.querySelectorAll(".quotes__entry");
    let quoteIndex = 1;
    document.querySelector(`.quote--${quoteIndex}`).style.display = "flex";

    setInterval(()=> {
        quotes.forEach(quote => {
            quote.style.display = "none"
            quote.style.opacity = 0
        })
        if (quoteIndex > quotes.length) {
            console.log('reset');
            quoteIndex=1
        }
        document.querySelector(`.quote--${quoteIndex}`).style.display = "flex";
        gsap.to(`.quote--${quoteIndex}`, {duration: 1, alpha: 1})
        console.log('', quoteIndex);

        quoteIndex++
    }, 5000)


  })
